import { configureStore } from '@reduxjs/toolkit'

import { navigationMiddleware } from '@/middleware/navigationMiddleware'
import selectedOptionsReducer from '@/store/slices/selectedOptionsSlice'
import userReducer from '@/store/slices/userSlice'
import terminalReducer from '@/store/slices/terminalSlice'

export const store = configureStore({
  reducer: {
    terminal: terminalReducer,
    user: userReducer,
    selectedOptions: selectedOptionsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(navigationMiddleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
