'use client'

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { windowMetadata } from '@/constants/windowMetadata'
import { setSelectedAndOverwrite } from '@/store/slices/selectedOptionsSlice'
import { setLoadTerminalImmediately } from '@/store/slices/terminalSlice'

interface HashNavigationProviderProps {
  children: React.ReactNode
}

const getNumericPrefix = (hash: string) => {
  return hash.slice(1).split('-')[0]
}

const isValidHash = (hash: string): boolean => {
  const hashWithoutPrefix = hash.slice(1)
  return windowMetadata.some((window) => window.title === hashWithoutPrefix)
}

export const HashNavigationProvider = ({
  children,
}: HashNavigationProviderProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const handleHash = () => {
      const hash = window.location.hash
      if (hash) {
        if (isValidHash(hash)) {
          const paneId = getNumericPrefix(hash)
          dispatch(setSelectedAndOverwrite(paneId))
          dispatch(setLoadTerminalImmediately(true))
        } else {
          window.history.pushState(null, '', '/')
        }
      }
    }

    handleHash()

    window.addEventListener('hashchange', handleHash)

    return () => window.removeEventListener('hashchange', handleHash)
  }, [dispatch])

  return <>{children}</>
}
