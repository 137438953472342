import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface TerminalState {
  loadTerminalImmediately: boolean
}

const initialState: TerminalState = {
  loadTerminalImmediately: false,
}

const terminalSlice = createSlice({
  name: 'terminal',
  initialState,
  reducers: {
    setLoadTerminalImmediately: (state, action: PayloadAction<boolean>) => {
      state.loadTerminalImmediately = action.payload
    },
  },
})

export const { setLoadTerminalImmediately } = terminalSlice.actions

export default terminalSlice.reducer
