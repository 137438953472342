import { Middleware } from '@reduxjs/toolkit'

import { windowMetadata } from '@/constants/windowMetadata'

interface ActionWithPayload {
  type:
    | 'selectedOptions/removeSelectedOption'
    | 'selectedOptions/setSelectedAndOverwrite'
  payload: string
}

export const navigationMiddleware: Middleware = () => (next) => (action) => {
  const typedAction = action as ActionWithPayload
  const result = next(action)

  if (typeof window === 'undefined') {
    return result
  }

  if (typedAction.type === 'selectedOptions/removeSelectedOption') {
    window.history.pushState(null, '', '/')
  } else if (typedAction.type === 'selectedOptions/setSelectedAndOverwrite') {
    const metadata = windowMetadata.find(
      (item) => item.key === typedAction.payload
    )
    window.history.pushState(null, '', `#${metadata?.title}`)
  }

  return result
}
